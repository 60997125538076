<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'ShoplineSetting' }"
              >SHOPLINE 設定
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              SHOPLINE 編輯設定
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">
            SHOPLINE 編輯設定
          </h4>
        </div>
      </div>

      <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary">
        <section class="row mb-8">
          <div class="col-12 col-xl-8">
            <b-form-group label-cols="12" label-cols-lg="2" label-size="sm" label="Shopline API Token" label-for="token">
              <b-form-textarea id="token" class="mb-2 mr-sm-2 mb-sm-0" v-model="form.token"></b-form-textarea>
            </b-form-group>
          </div>
        </section>
      </b-overlay>

      <div class="d-flex justify-content-center">
        <b-button class="mr-4" variant="outline-danger" :to="{ name: 'ShoplineSetting' }">取消</b-button>
        <b-overlay rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
          <b-button variant="success" @click="handleSubmit">
            確認修改
          </b-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import orgVariableApi from "@/apis/organization-variable";

export default {
  data() {
    return {
      isLoading: false,
      config: null,
      form: {
        token: "",
      },
      orgVariable: null,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;

      await this.fetchConfig();
      this.prepareForm();

      this.loading = false;
    },
    async fetchConfig() {
      try {
        const response = await orgVariableApi.getByKey({
          org_code: this.organization.id,
          key: "SHOPLINE-CONFIG",
        });

        this.orgVariable = response.data.data;
        this.config = JSON.parse(response.data.data.value || '{"credentials":{"token":""}}');
      } catch (error) {
        console.log(error);
      }
    },
    prepareForm() {
      this.form.token = this.config?.credentials?.token;
    },
    handleSubmit() {
      this.loading = true;
      this.config.credentials.token = this.form.token;
      this.orgVariable.value = JSON.stringify(this.config);

      try {
        orgVariableApi.update({
          org_code: this.organization.id,
          organizationVariable: this.orgVariable,
        });

        this.$swal.fire({
          type: "success",
          title: "SHOPLINE 設定已更新",
        }).then(() => {
          this.$router.push({ name: "ShoplineSetting" });
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
